.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 135%;
}

h1 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 700;
}

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 27px;
  line-height: 135%;
  font-weight: 700;
}

h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 30px;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

h5 {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

h6 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

p {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Merriweather, serif;
  line-height: 160%;
  font-weight: 300;
}

a {
  color: #24a7ff;
  text-decoration: underline;
}

ul {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 40px;
}

ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-family: Lato, sans-serif;
}

li {
  font-family: Merriweather, serif;
  font-weight: 300;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote {
  margin-bottom: 20px;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-family: Lora, sans-serif;
  font-size: 20px;
  line-height: 140%;
  font-style: italic;
  font-weight: 400;
}

figure {
  margin-bottom: 20px;
}

figcaption {
  margin-top: 8px;
  font-family: Merriweather, serif;
  color: #aaa;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.button {
  display: inline-block;
  padding: 11px 20px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #ddd;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  color: #333;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  box-shadow: 0 0 0 1px #333;
}

.button.w--current {
  background-color: #24336b;
  color: #fff;
}

.button.text-overflow {
  background-color: #24336b;
  color: #fff;
}

.navigation-link {
  margin-left: 25px;
  padding: 15px 0px;
  color: #aaa;
  font-size: 14px;
  text-decoration: none;
}

.navigation-link:hover {
  color: #333;
}

.navigation-link.w--current {
  color: #333;
}

.section {
  position: relative;
  padding-top: 63px;
  padding-bottom: 63px;
  background-color: #fff;
}

.section.accent {
  background-color: #f3f3f3;
}

.section.dark {
  background-color: #5d5d5d;
  color: #fff;
}

.header-section {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  text-align: center;
}

.social-button {
  margin-right: 4px;
  margin-bottom: 8px;
  margin-left: 4px;
  padding: 8px;
  border-radius: 100px;
  background-color: #000;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.social-button:hover {
  opacity: 0.7;
}

.social-button.border {
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.36);
  background-color: transparent;
}

.social-button.facebook {
  background-color: #3c5791;
}

.social-button.twitter {
  background-color: #29a9e8;
}

.social-button.red {
  background-color: #d11529;
}

.social-button.pink {
  background-color: #fc488f;
}

.social-button.pink2 {
  background-color: #ed1081;
}

.social-button.orange {
  background-color: #db4e34;
}

.social-button.vimeo {
  background-color: #1ebae7;
}

.social-button.linkdin {
  background-color: #1485c3;
}

.social-button.instagram {
  background-color: #4b769b;
}

.social-button.tumblr {
  background-color: #2e5270;
}

.social-button.webflow {
  background-color: #3278bd;
}

.social-button.medium {
  background-color: #549f63;
}

.social-icon-link {
  width: 30px;
  margin-right: 7px;
  margin-left: 7px;
  padding: 4px;
  opacity: 0.36;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.social-icon-link:hover {
  opacity: 0.8;
}

.nav-link {
  display: inline-block;
  margin-right: 22px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #aaa;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #2d3e4f;
}

.nav-link.w--current {
  color: #333;
}

.nav-link.float-right {
  margin-right: 0px;
  float: right;
}

.site-name {
  display: block;
  margin-top: 13px;
  color: #333;
  font-size: 24px;
  line-height: 100%;
  text-decoration: none;
}

.site-name.w--current {
  font-family: 'PT Serif', serif;
  font-weight: 700;
}

.sidebar-text {
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 145%;
  font-weight: 400;
  text-align: left;
}

.post-wrapper {
  margin-right: 0%;
  margin-bottom: 40px;
  margin-left: 0%;
  padding-bottom: 40px;
  border-bottom: 1px solid #d3d3d3;
  background-color: #fff;
}

.post-summary {
  display: block;
  margin-bottom: 14px;
  line-height: 144%;
}

.post-info {
  display: inline-block;
  margin-right: 8px;
  color: #969696;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.post-info.when-link {
  text-decoration: none;
}

.post-info.when-link:hover {
  color: #333;
}

.blog-title-link {
  display: block;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #333;
  text-decoration: none;
}

.blog-title-link:hover {
  opacity: 0.61;
}

.body-copy img {
  border-radius: 3px;
}

.body-copy a {
  text-decoration: none;
}

.body-copy a:hover {
  text-decoration: underline;
}

.body-copy li {
  margin-bottom: 3px;
}

.body-copy blockquote {
  margin-left: -23px;
  padding-left: 18px;
  border-left-width: 4px;
  border-left-color: #333;
}

.body-copy figure {
  margin-bottom: 20px;
}

.success-message {
  padding-top: 49px;
  padding-bottom: 49px;
  border-radius: 3px;
  background-color: #f0f0f0;
}

.success-text {
  margin-bottom: 0px;
  font-size: 17px;
}

.text-field {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.text-field.text-area {
  min-height: 110px;
}

.social-link-group {
  margin-top: 17px;
  margin-bottom: 3px;
}

.button-wrapper {
  margin-top: 0px;
  color: green;
  text-align: center;
}

.content-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}

.blog-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 37px;
}

.white-wrapper {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #ddd;
}

.circle-profile {
  display: block;
  width: 70%;
  margin-right: auto;
  margin-bottom: 28px;
  margin-left: auto;
  border-radius: 500px;
}

.section-header {
  margin: 0px 7% 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d3d3d3;
  background-color: #fff;
  font-size: 25px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.main-subheading {
  display: block;
  width: 70%;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  font-family: Merriweather, serif;
  color: #969696;
  font-size: 16px;
}

.footer {
  padding-top: 29px;
  padding-bottom: 29px;
  border-top: 1px solid #ddd;
  background-color: #f0f0f0;
}

.button-round {
  padding: 5px 12px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  background-color: #fff;
  -webkit-transition: border 200ms ease, color 200ms ease;
  transition: border 200ms ease, color 200ms ease;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.button-round:hover {
  border-color: #333;
  color: #333;
}

.post-info-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10px;
}

.menu-icon {
  margin-top: 3px;
  margin-right: 0px;
  float: left;
}

.menu-text {
  margin-left: 8px;
  float: left;
  line-height: 122%;
}

.dropdown-menu {
  z-index: 1;
  padding: 9px 11px;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

.dropdown-menu:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.menu-link {
  padding-right: 10px;
  padding-left: 10px;
  border-top: 1px solid #d1d1d1;
  background-color: #fff;
}

.menu-link:hover {
  background-color: #f0f0f0;
}

.menu-link.w--current {
  background-color: #f0f0f0;
  color: #333;
}

.menu-link.home {
  border-top-style: none;
}

.menu-list.w--open {
  overflow: hidden;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14), 0 6px 21px 2px rgba(0, 0, 0, 0.06);
}

.dropdown-wrapper {
  position: absolute;
  left: 20px;
  top: 20px;
}

.sidebar-copy h1 {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 125%;
}

.sidebar-copy p {
  margin-bottom: 10px;
  font-family: Merriweather, serif;
  font-size: 12px;
  font-weight: 400;
}

.sidebar-copy h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 125%;
}

.sidebar-copy h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 125%;
}

.main-title {
  display: block;
  margin-bottom: 15px;
  color: #333;
  font-size: 35px;
  line-height: 40px;
  font-weight: 700;
  text-decoration: none;
}

.main-title.w--current {
  margin-top: 7px;
  margin-bottom: 0px;
}

.content-column {
  padding-right: 22px;
}

.blog-body-wrapper {
  margin-right: 7%;
  margin-left: 7%;
}

.blog-post-header {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  text-align: center;
}

.blog-page-title {
  margin-right: 7%;
  margin-bottom: 19px;
  margin-left: 7%;
}

.submit-button {
  display: inline-block;
  border-radius: 3px;
  background-color: #24336b;
  text-align: left;
}

.image {
  display: inline-block;
  text-align: left;
}

.container {
  text-align: center;
}

.form {
  text-align: left;
}

.container-2 {
  max-width: 40%;
}

.container-3 {
  text-align: right;
}

.image-2 {
  margin-top: 97px;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.search {
  width: auto;
  margin-top: 30px;
  margin-bottom: -40px;
  text-align: right;
}

.field-label {
  text-align: center;
}

.container-4 {
  margin-top: 0px;
  margin-bottom: -25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.container-5 {
  margin-top: -44px;
}

.container-6 {
  margin-top: 25px;
}

.div-block {
  width: 25vh;
  height: 0.2vh;
  margin-top: 0px;
  margin-bottom: 27px;
  border: 0.5px solid #000;
}

.search-result-item {
  margin-top: 60px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d4d4d4;
}

.text-block {
  font-size: 20px;
}

.email-subject {
  font-size: 22px;
}

.text-field-2 {
  text-align: left;
}

.container-10 {
  max-width: 470px;
  margin-top: 40px;
}

.submit-button-2 {
  text-align: center;
}

.submit-button-6 {
  border-radius: 5px;
  background-color: #24336b;
}

.form-2 {
  max-width: 90%;
  text-align: right;
}

.form-3 {
  position: static;
  display: block;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.form-block {
  display: block;
  margin-top: 30px;
  text-align: center;
}

.field-label-2 {
  text-align: center;
}

.section-2 {
  margin-top: 40px;
}

.container-7 {
  margin-top: 20px;
}

.form-4 {
  text-align: right;
}

.submit-button-3 {
  border-radius: 3px;
  background-color: #24336b;
}

.text-field-3 {
  border: 1px solid #000;
  border-radius: 3px;
  opacity: 0.35;
}

.result-div {
  margin-bottom: 0px;
  text-align: left;
}

.div-block-2 {
  max-width: 40vh;
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
  opacity: 0.29;
}

.grid {
  grid-row-gap: 16px;
  grid-template-areas: ". . Area";
  -ms-grid-columns: 1fr 1fr 0.25fr;
  grid-template-columns: 1fr 1fr 0.25fr auto;
  -ms-grid-rows: auto 16px auto 16px auto 16px auto 16px auto 16px auto;
  grid-template-rows: auto auto auto auto auto auto;
  text-align: left;
}

.container-8 {
  margin-top: 30px;
}

.button-2 {
  width: auto;
  height: auto;
  border-radius: 3px;
  background-color: #24336b;
  text-align: center;
  text-decoration: none;
}

.button-2.create-user {
  border-radius: 3px;
}

.grid-2 {
  -ms-grid-columns: minmax(200px, 0.25fr);
  grid-template-columns: minmax(200px, 0.25fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-9 {
  margin-top: 20px;
}

.section-3 {
  margin-bottom: 152px;
}

.modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #a8d069;
  background-image: linear-gradient(45deg, #fff, #24336b), linear-gradient(180deg, #24336b, #24336b);
}

.div-block-3 {
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-block-4 {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
  width: 30px;
  padding: 5px;
}

.pop-up-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #24336b;
  opacity: 0;
}

.form-block-2 {
  width: 100%;
  max-width: 500px;
}

.popup-form {
  width: 100%;
  max-width: 500px;
  background-color: rgba(122, 68, 68, 0);
  opacity: 1;
}

.pop-up-close {
  position: absolute;
  top: 5%;
  right: 5%;
}

.field-label-3 {
  color: #fff;
}

.field-label-4 {
  color: #fff;
}

.text-field-4 {
  border-radius: 3px;
}

.text-field-5 {
  border-radius: 3px;
}

.btn-overflow {
  border-radius: 3px;
  background-color: #24336b;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .navigation-link {
    text-align: center;
  }

  .navigation-bar {
    padding-bottom: 5px;
  }

  .navigation-menu {
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }

  .header-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .button-wrapper {
    margin-top: 60px;
  }

  .content-wrapper {
    padding: 60px 20px;
  }

  .white-wrapper {
    padding: 14px;
  }

  .menu-button {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .navigation-bar {
    margin-right: 0%;
    margin-left: 0%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .header-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .social-icon-link {
    margin-right: 2px;
    margin-left: 2px;
  }

  .nav-link {
    display: inline-block;
    margin: 0px 12px;
  }

  .nav-link.float-right {
    float: none;
  }

  .post-wrapper {
    margin-right: 0%;
    margin-bottom: 30px;
    margin-left: 0%;
    padding-bottom: 30px;
  }

  .body-copy blockquote {
    margin-left: 0px;
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
  }

  .content-wrapper {
    width: 100%;
    margin-left: 0px;
    padding: 30px 20px;
  }

  .blog-title {
    font-size: 27px;
    line-height: 36px;
  }

  .white-wrapper {
    margin-top: 20px;
    padding: 34px;
  }

  .section-header {
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0%;
    padding-bottom: 30px;
  }

  .main-subheading {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  .footer {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .dropdown-wrapper {
    position: relative;
    left: 0px;
    top: 0px;
    margin-top: 15px;
  }

  .blog-body-wrapper {
    margin-right: 0%;
    margin-left: 0%;
  }

  .blog-post-header {
    padding: 30px 20px;
  }

  .blog-page-title {
    margin-right: 0%;
    margin-left: 0%;
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (max-width: 479px) {
  .nav-link {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .blog-title {
    font-size: 26px;
    line-height: 32px;
  }

  .white-wrapper {
    padding: 20px;
  }

  .footer {
    text-align: center;
  }
}

#w-node-b6503a5d-7fa2-a3b4-121d-2fe9d1d397f5-7b74dd4d {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row-align: auto;
  align-self: auto;
}
