@import '@aws-amplify/ui-react/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

:root {
  --amplify-fonts-default-variable: 'Open Sans';
  --amplify-fonts-default-static: 'Open Sans';
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.amplify-tabs {
  display: none;
}

.amplify-button[data-variation='primary'] {
  background-color:rgb(0,157,206)
}

.amplify-button[data-variation='primary']:hover {
  background-color:rgb(0,86,112)
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: #ea7a31;
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #4cb0d5;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: #ea7a31;
  }
}
* {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  padding-bottom: 100px;
}

h1, h3 {
  display: block;
  margin: 0px auto;
  text-align: center;
  font-family: "Tahoma";
  font-weight: lighter;
  color: #24336B;
  letter-spacing: 1.5px;
}

h1 {
  margin: 150px auto;
}

.loader {
  position: relative;
  margin: 75px auto;
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
}
.loader div {
  height: 100%;
}

/* loader 1 */
.loader1, .loader1 div {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2.5s infinite;
  border-top-color: #9da4b0;
  border-bottom-color: #027D95;
}

/*loader 2  */
.loader2, .loader2 div {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2.5s infinite;
  border-top-color: #4cb0d5;
  border-left-color: #ea7a31;
  border-right-color: #ea7a31;
}

/*loader 3  */
.loader3, .loader3 div {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2.5s infinite;
  border-top-color: #ea7a31;
  border-left-color: #4cb0d5;
  animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
  animation-duration: 3s;
}

/* loader 4 */
.loader4, .loader4 div {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2.5s infinite;
  border-radius: 50%;
  padding: 4px;
  animation: rotate2 4s infinite linear;
}

div:hover {
  animation-play-state: paused;
}

.loader, .loader * {
  will-change: transform;
}